.rewards {
    width: 100%;
    overflow: auto;

    table {
        width: 100%;
        border: 1px #D7D7D7 solid;

        tr {
            padding: 10px;
        }
    }
}

tbody,
td,
tfoot,
th,
thead,
tr {
    padding: 10px;
    margin: 10px;
}

thead {
    tr {
        color: #2E3823;
        font-size: 13px;
        font-weight: 700;
        letter-spacing: 0.26px;
        word-wrap: break-word
    }
}

tbody {
    th {
        color: #2E3823;
        font-size: 18px;
        font-weight: 400;
        line-height: 26px;
        word-wrap: break-word
    }
}