.form-modal {
    background: #2D5E97;
    padding: 44px 33px !important;


    .form-header {
        color: white;
        font-size: 28px;
        font-weight: 600;
        line-height: 26px;
        letter-spacing: 0.56px;
        word-wrap: break-word
    }

    .close-modal {
        position: absolute;
        top: 20px;
        right: 20px;
        cursor: pointer;
    }

    .details-helper {
        color: white !important;
        font-size: 17px;
        font-family: Open Sans;
        font-weight: 400;
        line-height: 23px;
        word-wrap: break-word;
    }
}

.redemption-success {
    // Your Password has been reset.
    color: white !important;
    font-size: 28px;
    font-family: Open Sans;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0.56px;
    word-wrap: break-word
}