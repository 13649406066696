@import "./assets/styles/fonts.scss";
@import "./assets/styles/button.scss";
@import "./assets/styles/padMar.scss";
@import "./assets/styles/cards.scss";
@import 'bootstrap/dist/css/bootstrap.min.css';
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap');
@import '~react-toastify/dist/ReactToastify.min.css';

body {
  margin: 0;
  font-family: 'Open Sans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: auto !important;
  padding-right: 0px !important;
}

code {
  font-family: 'Open Sans';
}

@font-face {
  font-family: IntrudingCat;
  src: url(assets/fonts/intrudingcat.otf);
}


* {
  font-family: Open Sans !important;
  font-style: normal;
}

.toast-danger-container {
  background: red;
}

