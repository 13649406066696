.stepper {
    width: 70%;
}

.divider {
    border-bottom: 1px dashed #C2C2C2;
    width: 20%;
    height: 25px;
}

.i-icon {
    position: relative;
}

.modal-label {
    color: white;
    font-size: 13px;
    font-weight: 700;
    letter-spacing: 0.26px;
    word-wrap: break-word
}

.icon-eye {
    position: absolute;
    right: 8px;
    cursor: pointer;
    top: 10px;
}

.step-container {

    .step {
        background: #2D5E9780;
        color: white;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        font-family: Open Sans;
        font-size: 17px;
        font-weight: 700;
        line-height: 23px;
        letter-spacing: 0.02em;
        text-align: center;
        padding: 10px;
        cursor: pointer;
    }

    .active {
        background: #2D5E97;
    }
}

.s-label {
    color: #2E3823;
    font-size: 16px;
    font-weight: 700;
    word-wrap: break-word;
    line-height: 22px;
    letter-spacing: 0.02em;
}

.modal-input {
    height: 42px;
    border: none;
    padding: 2px 5px;
    width: 100%;
    border-radius: 4px;
}

.modal-input:focus {
    outline: none;
}

.form-input:focus {
    outline: none;
}

.form-textarea:focus {
    outline: none;
}

.form-input {
    background: white;
    border-radius: 4px;
    border: 1px #D6D6D6 solid;
    width: 100%;
    height: 48px;
    padding: 10px;
    .options:hover {
        background: #2D5E97 !important;
        color: white !important;
      }
}

.form-check {
    display: flex !important;
    align-items: center;
    gap: 1rem;

    input[type="checkbox"] {
        height: 20px;
        width: 20px;
        border: 2px solid #D7D7D7;
        margin: 12px 10px 4px 0px;
    }
}

.form-label {
    color: #2E3823;
    font-size: 13px;
    font-weight: 700;
    letter-spacing: 0.26px;
    word-wrap: break-word;
    text-transform: uppercase;
}

.normal-label {
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0.02em;
    text-align: left;
    padding: 10px 0 10px 0;
}

.radio-group {
    gap: 0.5rem;

    input[type="radio"] {
        width: 15px;
        height: 15px;
        accent-color: #2D5E97;
    }

    .radio-label {
        font-size: 16px;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: 0em;
        text-align: left;

    }

    .modal-radio {
        color: white;
        font-size: 18px;
        font-weight: 400;
        line-height: 30px;
        word-wrap: break-word
    }
}




.form-textarea {
    background: white;
    border-radius: 4px;
    border: 1px #D6D6D6 solid;
    width: 100%;
    padding: 10px;
}

.white-label {
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0.5133333802px;
    text-align: left;
    color: white;
}

@media (max-width: 793px) {
    .stepper {
        width: 100%;
        justify-content: center;
        padding: 20px 0 20px 0;
    }

    .form-check {
        width: 180px;
    }
}