.custom-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 16px;
  
    button {
        border: 0px solid #d9d9d9;
        background-color: #fff;
        color: #333;
        padding: 8px 16px;
        margin: 0 4px;
        cursor: pointer;
        border-radius: 10px;
        transition: background-color 0.3s;
  
      &:hover {
        background-color: #e2e1e1;
      }
  
      &:disabled {
        cursor: not-allowed;
      }
  
      &.active {
        border: 1px solid #d9d9d9;
        color: #1890ff;
        border-color: #1890ff;

        &:hover {
            background-color: white;
          }

      }
    }
  }
  