.my-benefits {
    color: white;
    gap: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 22px;
    .my-benefits-header {
        width: 80%;
        text-align: left;
        p {
            font-size: 32px;
            font-weight: 700;
            line-height: 38px;
            letter-spacing: 2.3578946590423584px;
            text-align: left;
        }
    }
    .my-benefits-contents {
        width: 80%;
        background: #2D5E97;
        box-shadow: 0px 4px 4px 0px #00000040;
        border-radius: 5px, 5px, 3px, 3px;
        gap: 5rem;
        padding: 50px;
    }
    .my-benefits-condition {
        width: 80%;
        text-align: left;
        p {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.5866667032241821px;
            text-align: left;

        }
    }
    
}

@media (max-width: 793px) {
    .my-benefits {
        padding-top: 10px;
        padding-bottom: 60px;
    }
    .my-benefits-contents {
        flex-direction: column;
        padding: 10px !important;
        gap: 2rem !important;
    }
}