@import 'variables';


.primary-button {
    width: fit-content;
    height: 100%;
    background: $secondary-color !important;
    border-radius: 28px;
    border: 1px solid $secondary-color;
    color: #1C355E;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 1px;
    white-space: nowrap;
    padding: 5px 24px;
    cursor: pointer;

    &.disabled {
        opacity: 0.6;
    }
}

.secondary-button {
    height: 100%;
    background: $color-blue !important;
    border-radius: 28px;
    border: 1px $color-blue solid;
    color: white;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
    white-space: nowrap;
    padding: 5px 24px;
    cursor: pointer;

    &.disabled {
        opacity: 0.6;
    }
}

.white-button {
    background: white !important;
    border-radius: 28px !important;
    border: 1px #1C355E solid !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    letter-spacing: 1px !important;
    white-space: nowrap;
    padding: 5px 24px !important;
    line-height: 17px !important;
    text-transform: uppercase;
    cursor: pointer;

    &.disabled {
        opacity: 0.6;
    }
}

.primary-header-button {
    background: $color-blue !important;
    border-radius: 28px !important;
    border: 1px $color-blue solid !important;
    color: white !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    letter-spacing: 1px !important;
    white-space: nowrap;
    padding: 5px 24px !important;
    text-transform: uppercase;
    line-height: 17px !important;
    cursor: pointer;

    &.show {
        background: white !important;
        color: $color-blue !important;
    }
}


.banner-login-button {
    background: white !important;
    border-radius: 28px !important;
    border: 1px $secondary-color solid !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    letter-spacing: 1px !important;
    white-space: nowrap;
    text-transform: uppercase;
    padding: 5px 24px !important;
    line-height: 17px !important;
    cursor: pointer;
}

.banner-signup-button {
    background: $color-blue !important;
    border-radius: 28px !important;
    border: 1px $secondary-color solid !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    color: white;
    text-transform: uppercase;
    letter-spacing: 1px !important;
    white-space: nowrap;
    padding: 5px 24px !important;
    line-height: 17px !important;
    cursor: pointer;
}

button:focus {
    outline: none;
}