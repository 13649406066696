.my-benefits-card {
    // height: 180px;
    width: 25%;
    text-align: center;
    position: relative;
    align-items: center;
    justify-content: center;
    gap: 40px;

    .benefits-img {
        height: 100px;

        img {
            height: 100px;
        }
    }

    .para {
        white-space: nowrap;
        text-align: center;
    }

    .bonus {
        font-size: 65px;
        font-weight: 300;
        line-height: 24px;
        letter-spacing: -3px;
        text-align: center;

        font-size: 65px;
        font-weight: 300;
        line-height: 24px;
        letter-spacing: -8px;
        text-align: center;

        font-size: 65px;
        font-weight: 300;
        line-height: 24px;
        letter-spacing: 0.5866667032241821px;
        text-align: center;
    }

}

.my-benefits-card:nth-child(3n)::after {
    display: none;
}

.my-benefits-card:last-child {
    padding-left: 40px;
}

.my-benefits-card:not(:last-child)::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: -55px;
    width: 1px;
    background: #4575AC;
    margin-right: 10px;
}

@media (max-width: 930px) {
    .my-benefits-card {
        width: 40%;
        margin-bottom: 10px;
        border-bottom: 1px solid #4575AC;
        padding-bottom: 10px;

        &::after {
            display: none;
        }
    }
}

@media (max-width: 793px) {

    .my-benefits-card {
        width: 100%;
        gap: 15px !important;

        .para {
            white-space: normal !important;
            text-align: center;
        }
    }

    .my-benefits-card:last-child {
        padding-left: 0px !important;
    }

}