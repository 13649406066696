.my-status {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    color: white;
    padding-top: 100px;
    padding-bottom: 170px;
    background: linear-gradient(0deg, rgba(28, 53, 94, 4.5e-05) 46.39%, rgba(28, 53, 94, 0.45) 100%),
        linear-gradient(0deg, #2D5E97, #2D5E97),
        linear-gradient(157.21deg, rgba(70, 120, 178, 0.0001) 41.32%, rgba(70, 120, 178, 0.419936) 95%);
}




@media (max-width: 793px) {
    .my-status {
        padding-top: 20px;
        height: auto !important;
    }

}