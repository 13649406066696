.login-modal {
    width: 500px;
    background: #2E5E97;
    margin: 100px 30px;
    gap: 1rem;
    border-radius: 5px;
}


.login-form-modal {
    padding: 44px 33px !important;
    border-radius: 5px;

    .form-label {
        color: white;
        font-size: 17px;
        font-weight: 400;
        line-height: 26px;
        word-wrap: break-word
    }

    .form-header {
        color: white;
        font-size: 28px;
        font-weight: 600;
        line-height: 26px;
        letter-spacing: 0.56px;
        word-wrap: break-word
    }

    .close-modal {
        position: absolute;
        top: 20px;
        right: 20px;
        cursor: pointer;
    }

    .forgot-password {
        color: white;
        font-size: 17px;
        font-weight: 400;
        text-decoration: underline;
        line-height: 26px;
        word-wrap: break-word;
        cursor: pointer;
    }

    .first-time-sign-in {
        color: white;
        font-size: 17px;
        font-weight: 400;
        line-height: 26px;
        word-wrap: break-word;
    }
}

@media only screen and (max-width: 767px) {
    .login-modal {
        width: 85% !important;
        height: auto !important;
        margin: 20px !important;
    }
}