@import './../../../assets/styles/variables.scss';

.find-hotels {
    width: 400px;
    background: #2E5E97;
    padding: 30px 20px;
    margin: 80px 30px;
    gap: 1rem;
    border-radius: 5px;

    .white {
        color: white;
    }

    .destination {
        width: 100%;
        gap: 0.5rem;

        .destination-text {
            display: flex;
            gap: 0.4rem;
            color: white;
            font-size: 12px;
            align-items: center;

            img {
                width: auto;
                height: 20px;
            }

            p {
                font-size: 14px;
                font-weight: 600;
                line-height: 19px;
                letter-spacing: 0.5133333802223206px;
                text-align: left;
            }
        }

        input {
            width: 100%;
            padding: 10px;
            margin: 0;

            &::placeholder {
                font-size: 16px;
                font-weight: 600;
                line-height: 30px;
                letter-spacing: 0.8066666722297668px;
                text-align: left;
            }

        }
    }

    .select-date {
        width: 100%;
        gap: 0.5rem;

        .select-date-text {
            display: flex;
            gap: 0.4rem;
            color: white;
            font-size: 12px;
            align-items: center;

            img {
                width: auto;
                height: 20px;
            }

            p {
                font-size: 14px;
                font-weight: 600;
                line-height: 19px;
                letter-spacing: 0.5133333802223206px;
                text-align: left;
            }
        }

        input {
            width: 100%;
            padding: 10px;
            margin: 0;
            font-size: 18px;

            &::placeholder {
                font-size: 22px;
                font-weight: 600;
                line-height: 30px;
                letter-spacing: 0.8066666722297668px;
                text-align: left;
            }

        }

        fieldset {
            border: none;
        }
    }
}

.guest-and-rooms {
    gap: 1.5rem;
}


@media (max-width: 793px) {
    .ant-picker-panels {
        display: grid !important;
        flex-wrap: wrap !important;
        direction: ltr;
    }

    .find-hotels {
        width: 85%;
        height: auto;

        .find-hotels-filter-container {
            .find-hotels-filter-part-one {
                .destination {
                    width: 30%;
                    gap: 0.5rem;

                    .destination-text {
                        display: flex;
                        gap: 0.4rem;
                        color: white;
                        font-size: 12px;
                        align-items: center;

                        img {
                            width: auto;
                            height: 13.78px;
                            padding: 0.53px, 0.5px, 0.43px, 0.52px;
                        }

                        p {
                            font-size: 12px;
                            font-weight: 600;
                            line-height: 16px;
                            letter-spacing: 0.4400000274181366px;
                            text-align: left;

                        }
                    }

                    input {
                        color: white;
                        width: 100%;
                        border: none;
                        outline: none;
                        padding: 0;
                        margin: 0;
                        background-color: rgb(45, 94, 151, 0.85);

                        &::placeholder {
                            color: white;
                            font-size: 15px;
                            font-weight: 600;
                            line-height: 20px;
                            letter-spacing: 0.550000011920929px;
                            text-align: left;
                        }

                    }
                }

                .select-date {
                    width: 30%;
                    gap: 0.5rem;

                    .select-date-text {
                        display: flex;
                        gap: 0.4rem;
                        color: white;
                        font-size: 12px;
                        align-items: center;

                        img {
                            width: auto;
                            height: 13.78px;
                            padding: 0.53px, 0.5px, 0.43px, 0.52px;

                        }

                        p {
                            font-size: 12px;
                            font-weight: 600;
                            line-height: 16px;
                            letter-spacing: 0.4400000274181366px;
                            text-align: left;
                        }
                    }

                    input {
                        color: white;
                        width: 100%;
                        border: none;
                        outline: none;
                        padding: 0;
                        margin: 0;
                        background-color: rgb(45, 94, 151, 0.85);

                        &::placeholder {
                            color: white;
                            font-size: 15px;
                            font-weight: 600;
                            line-height: 20px;
                            letter-spacing: 0.550000011920929px;
                            text-align: left;
                        }
                    }
                }
            }
        }

        .find-hotels-button {
            // display: none;
        }
    }

    .range-picker {
        height: 48px;
    }
    
    @media (max-width: 992px) {
        .ant-picker-panel:last-child .ant-picker-date-panel {
          display: none !important;
        }
      
        .ant-picker-panel:first-child
          .ant-picker-date-panel
          .ant-picker-header-next-btn,
        .ant-picker-panel:first-child
          .ant-picker-date-panel
          .ant-picker-header-super-next-btn {
          visibility: initial !important;
        }
      
        .ant-picker-panel.ant-picker-range-wrapper {
          min-width: 288px !important;
        }
      
        .ant-picker-presets {
          display: none !important;
        }
      }

}