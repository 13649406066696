.secondary-footer {
    background-color: #30302F;
    width: 100%;
    height: 95px;
    gap: 1.5rem;
    .secondary-footer-content {
        font-size: 14px;
        font-weight: 400;
        line-height: 44px;
        letter-spacing: 0.5133333802223206px;
        text-align: center;
        color: white;
    }
    span {
        text-decoration: underline;
        cursor: pointer;
    }
}

@media (max-width: 793px) {
    .secondary-footer {
        flex-direction: column;
        height: auto;
        padding: 20px;
        gap: 1rem;
    }
  }