.form-modal-otp-dark {
    background: #2E5E97;
    padding: 44px 33px !important;
    border-radius: 5px;
    align-items: left;
    text-align: left;

    .form-header-otp {
        color: white;
        font-family: Open Sans;
        font-size: 28px;
        font-style: normal;
        font-weight: 600;
        line-height: 26px;
        /* 92.857% */
        letter-spacing: 0.56px;
    }

    .form-sub-header-otp {
        color: white;
        font-family: Open Sans;
        font-size: 17px;
        font-style: normal;
        font-weight: 400;
        line-height: 23px;
        /* 135.294% */
    }

    .close-modal {
        position: absolute;
        top: 20px;
        right: 20px;
        cursor: pointer;
    }

    .form-label-otp {
        color: white;
        font-family: Open Sans;
        font-size: 17px;
        font-style: normal;
        font-weight: 700;
        line-height: 23px;
        /* 135.294% */
    }

    .style-otp {
        width: 100%;
        text-align: left;
        justify-content: left;

        input {
            min-width: 52.545px;
            min-height: 53.483px;
            border: 1px solid;
            margin-right: 20px;
            border-radius: 4px;
            border: 1px solid #B0B0B0;
        }

        input:focus {
            outline: none;
            border-radius: 4px;
            border: 1px solid #7CB9FF;
        }

        input[type=number]::-webkit-inner-spin-button,
        input[type=number]::-webkit-outer-spin-button {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            margin: 0;
        }
    }

    //handling mobile view
    @media (max-width:600px){
        .style-otp{
            display: flex;
            justify-content: space-between;            
            flex-wrap: nowrap;
            gap: 5px;            

            input{
                flex: 1 1 15%;
                min-width: 15%;
                max-width: 15%;
                min-height: 52px;
                margin-right: 0;
            }
        }
    }

    .helper {
        color: white;
        font-family: Open Sans;
        font-size: 17px;
        font-style: normal;
        font-weight: 400;
        line-height: 23px;
        /* 135.294% */
    }

    .list-type {
        list-style: disc;
    }

    .resend-code {
        color: #2D5E97;
        font-size: 17px;
        font-weight: 400;
        text-decoration: underline;
        line-height: 16px;
        word-wrap: break-word;
        cursor: pointer;
    }

    .form-sub-header-otp {
        margin-bottom: 0px;
    }
}

.form-modal-otp {
    background: white;
    padding: 44px 33px !important;
    border-radius: 5px;
    align-items: left;
    text-align: left;

    .form-header-otp {
        color: #000;
        font-family: Open Sans;
        font-size: 28px;
        font-style: normal;
        font-weight: 600;
        line-height: 26px;
        /* 92.857% */
        letter-spacing: 0.56px;
    }

    .form-sub-header-otp {
        color: #000;
        font-family: Open Sans;
        font-size: 17px;
        font-style: normal;
        font-weight: 400;
        line-height: 23px;
        /* 135.294% */
    }

    .close-modal {
        position: absolute;
        top: 20px;
        right: 20px;
        cursor: pointer;
    }

    .form-label-otp {
        color: #000;
        font-family: Open Sans;
        font-size: 17px;
        font-style: normal;
        font-weight: 700;
        line-height: 23px;
        /* 135.294% */
    }

    .style-otp {
        width: 100%;
        text-align: left;
        justify-content: left;

        input {
            min-width: 52.545px;
            min-height: 53.483px;
            border: 1px solid;
            margin-right: 20px;
            border-radius: 4px;
            border: 1px solid #B0B0B0;
        }

        input:focus {
            outline: none;
            border-radius: 4px;
            border: 1px solid #7CB9FF;
        }

        input[type=number]::-webkit-inner-spin-button,
        input[type=number]::-webkit-outer-spin-button {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            margin: 0;
        }
    }

    .helper {
        color: #000;
        font-family: Open Sans;
        font-size: 17px;
        font-style: normal;
        font-weight: 400;
        line-height: 23px;
        /* 135.294% */
    }

    .list-type {
        list-style: disc;
    }

    .resend-code {
        color: #2D5E97;
        font-size: 17px;
        font-weight: 400;
        text-decoration: underline;
        line-height: 16px;
        word-wrap: break-word;
        cursor: pointer;
    }
}