.banner-content {
    color: white;
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    // opacity: 0.3073;
    // background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 24.54%, rgba(0, 0, 0, 0.83) 82.95%);
    .banner-text {
        width: 50%;
        gap: 1rem;

        .banner-header {
            color: white;
            font-size: 60px;
            font-family: Open Sans;
            font-weight: 400;
            line-height: 62px;
            letter-spacing: 3.10px;
            word-wrap: break-word
        }

        .banner-helper {
            color: white;
            font-size: 16px;
            font-family: Open Sans;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.59px;
            word-wrap: break-word;
            margin-top: 17px;

            span {
                color: white;
                text-decoration: underline;
                cursor: pointer;
            }

        }
    }

}

@media (max-width: 793px) {
    .banner-content {
        height: 70vh;
        justify-content: space-between;
        padding: 50px 0 50px 0;

        .banner-text {
            width: 100%;
            margin-left: 10%;

            .banner-header {
                font-size: 32px;
                font-weight: 400;
                line-height: 32px;
                letter-spacing: 1.6538951396942139px;
                text-align: left;
                width: 100% !important;

            }

            .banner-helper {
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                letter-spacing: 0.5133333802223206px;
                text-align: left;
            }
        }
    }



}