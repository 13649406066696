.header-main {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    padding: 0px 75px !important;
    height: 135px;
    flex-shrink: 0;
    background: #FFF !important;
    backdrop-filter: blur(10.873126983642578px);

    .logo {
        height: 89px;
        cursor: pointer;
    }

    .header-links {
        gap: 30px;
        margin-left: 30px;

        span {
            color: #30302F;
            font-size: 16px;
            font-weight: bold;
            line-height: 44px;
            word-wrap: break-word;
            cursor: pointer;
            margin-left: 30px;
            &.mt-15 {
                margin-top: 15px;
            }

            &.mt-15::after {
                vertical-align: 2px !important;
            }

            &.active {
                text-decoration: underline;
            }
        }

        span:hover {
            transform: translateY(-1px) scale(1.005) translateZ(0);
        }
    }

    .header-links-profile {
        span {
            color: #30302F !important;
            font-size: 14px;
            font-weight: bold;
            line-height: 44px;
            // margin-right: 20px;
            white-space: nowrap;
            cursor: pointer;

            &.mt-15 {
                margin-top: 15px;
            }

            &.mt-15::after {
                vertical-align: 2px !important;
            }

            &.active {
                text-decoration: underline;
            }
        }

        span:hover {
            transform: translateY(-1px) scale(1.005) translateZ(0);
        }
    }

    .rewarded {
        font-family: IntrudingCat,sans-serif !important;
    }

    .custom-dropdown {
        top: 50px !important;
        left: 52px !important;
        background: #1C355E;
        border-radius: 5px;

        span {
            color: white;
            font-size: 14px;
            font-weight: 600;
            line-height: 44px;
            white-space: nowrap;
            text-decoration: underline;
            margin-left: 0px;
            cursor: pointer;
        }

        span:hover {
            color: #30302F;
        }
    }

    /* SearchComponent.css */

    .search-container {
        position: relative;
        display: inline-block;
    }

    .search-icon {
        cursor: pointer;

        img {
            height: 24px;
        }
    }

    .search-input-container {
        display: none;
        position: absolute;
        right: 0;
        top: 100%;
        transition: all 0.3s ease;
    }

    .search-container.open .search-input-container {
        display: block;
    }

    .search-input {
        width: 150px;
        padding: 5px;
    }

    .user-greet-desktop {
        font-size: 14px;
        font-weight: bold;
        line-height: 44px;
        letter-spacing: 0px;
        text-align: left;
        color: #2D5E97;
    }

}

.menu-c {
    color: #2D5E97;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.44px;
}

.user-name {
    white-space: nowrap;
    margin-left: 20px;
}

@media only screen and (max-width: 1000px) {
    .user-name {
        margin-left: 0px !important;
    }
    .header-main {
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
        padding: 9px 17px !important;
        height: auto !important;

        .logo {
            height: 48px !important;
            cursor: pointer;
        }

        .header-links {
            gap: 0px !important;
            margin-left: 0px !important;

            span {
                color: #30302F;
                font-size: 16px;
                font-weight: 600;
                line-height: 44px;
                word-wrap: break-word;
                margin-left: 0px !important;
                cursor: pointer;

                &.mt-15 {
                    margin-top: 15px;
                    width: fit-content !important;
                    margin-bottom: 20px;
                }

                &.mt-15::after {
                    vertical-align: 2px !important;
                }

                &.active {
                    text-decoration: underline;
                }
            }

            span:hover {
                transform: translateY(-1px) scale(1.005) translateZ(0);
            }
        }

        .search-container {
            display: none !important;
        }

        .navbar-nav .dropdown-menu {
            position: absolute;
            width: fit-content;

            .dropdown-item {
                color: white !important;
            }
        }
    }

    .find-hotel {
        display: none !important;
    }
}