.form-modal-complete {
    background: #2D5E97;
    padding: 44px 33px !important;
    border-radius: 5px;

    .form-label {
        color: white;
        font-size: 13px;
        font-weight: 700;
        letter-spacing: 0.26px;
        word-wrap: break-word;
        margin-bottom: 0px;
    }

    .form-header {
        color: white;
        font-size: 28px;
        font-weight: 600;
        line-height: 26px;
        letter-spacing: 0.56px;
        word-wrap: break-word
    }

    .close-modal {
        position: absolute;
        top: 20px;
        right: 20px;
        cursor: pointer;
    }

    .password-helper {
        color: #FFF;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px
    }
}


@media only screen and (max-width: 767px) {
    .form-modal-complete {

        // width: 90% !important;
        .form-label {
            color: white;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            word-wrap: break-word
        }

        .form-header {
            color: white;
            font-size: 20px;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: 0.56px;
            word-wrap: break-word
        }
    }
}