.signup-modal {
    width: 500px;
    background: #2E5E97;
    margin: 80px 30px;
    gap: 1rem;
    border-radius: 5px;
}


.form-modal-signup {
    padding: 44px 33px !important;

    .form-label {
        color: white;
        font-size: 17px;
        font-weight: 400;
        line-height: 26px;
        word-wrap: break-word
    }

    .form-header {
        color: white;
        font-size: 28px;
        font-weight: 600;
        line-height: 26px;
        letter-spacing: 0.56px;
        word-wrap: break-word
    }

    .close-modal {
        position: absolute;
        top: 20px;
        right: 20px;
        cursor: pointer;
    }

    .password-helper {
        color: #FFF;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px
    }
}


@media only screen and (max-width: 767px) {
    .signup-modal {
        width: 85% !important;
        height: auto !important;
        margin: 20px !important;
    }
    .form-modal-signup {

        // width: 90% !important;
        .form-label {
            color: white;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            word-wrap: break-word
        }

        .form-header {
            color: white;
            font-size: 20px;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: 0.56px;
            word-wrap: break-word
        }
    }
}