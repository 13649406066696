.my-status-header {
    width: 75%;

    h1 {
        font-size: 32px;
        font-weight: 700;
        line-height: 38px;
        letter-spacing: 2.358px;
        height: 57px;
        padding-bottom: 13px;
    }
}

.my-status-contents {
    background: #2D5E97;
    box-shadow: 0px 4px 4px 0px #00000040;
    height: 360px;
    width: 75%;
}

.user-details {
    height: 360px;
    gap: 1.5rem;

    .user-details-content {
        gap: 1rem;

        .user-logo {
            img {
                width: 55px;
                height: 55px;
            }
        }

        .user-name {
            p {
                font-size: 32px;
                font-weight: 700;
                line-height: 26px;
                letter-spacing: 0.755px;
            }
        }

        .user-id {
            p {
                font-size: 16px;
                font-weight: 600;
                line-height: 36px;
                letter-spacing: 0.32px;
            }
        }
    }
}


.progress-chart {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
    position: relative;

    p {
        font-size: 16px;
        font-weight: 600;
        line-height: 26px;
        letter-spacing: 0.32px;
    }

    .complete-profile {
        text-decoration: underline;
        cursor: pointer;
        position: absolute;
        top: 150px;
    }
}

.user-points {

    gap: 1rem;

    h2 {
        font-size: 60px;
        font-weight: 600;
        line-height: 26px;
    }

    p {
        font-size: 14px;
        font-weight: 600;
        line-height: 26px;
        letter-spacing: 0.28px;
    }

    .points {
        height: 50px;

        span {
            align-self: end;
        }
    }
}

.arc-progress {
    font-size: 18px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.36px;
}

@media (max-width: 793px) {
    .user-details {
        height: auto;

        .user-details-content {
            .user-logo {
                img {
                    width: 44px;
                    height: 44px;
                }
            }

            .user-name {
                p {
                    font-size: 26px;
                    font-weight: 700;
                    line-height: 26px;
                    letter-spacing: 0.0236em;
                    text-align: center;

                }
            }

            .user-id {
                p {
                    font-size: 13px;
                    font-weight: 600;
                    line-height: 22px;
                    letter-spacing: 0.02em;
                    text-align: center;
                }
            }
        }
    }

    .my-status-contents {
        height: auto !important;
        flex-direction: column;
        width: 90%;
        // height: 577px;
        padding: 50px 0 50px 0;

        .progress-chart {
            margin-top: 0;
            padding-top: 50px;

            p {
                font-size: 14px;
                font-weight: 600;
                line-height: 26px;
                letter-spacing: 0.02em;
                text-align: center;

            }

            .complete-profile {
                top: 200px;
            }
        }
    }

    .user-points {
        gap: 4rem;

        h2 {
            font-size: 60px;
            font-weight: 600;
            line-height: 26px;
        }

        p {
            font-size: 14px;
            font-weight: 600;
            line-height: 26px;
            letter-spacing: 0.28px;
        }

        .points {
            flex-direction: column;
            gap: 1rem;

            span {
                align-self: center;
            }
        }
    }
}