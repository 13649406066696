.primary-card {
    border-radius: 5px;
    opacity: 0.9;
    background: #FFF;
    box-shadow: 0px 5px 15px 1px rgba(0, 0, 0, 0.10);
    backdrop-filter: blur(13.591408729553223px);
    transition: all 0.3s ease-out;

    .card-img {
        width: 100%;
        height: 395px !important;
        border-radius: 5px;
        object-fit: cover;
        &.small {
            height: 364px !important;
        }
    }

    .card-content {
        padding: 25px 22px;

        .card-header {
            color: #30302F;
            font-size: 22px;
            font-weight: 600;
            line-height: 27px;
            letter-spacing: 0.88px;
            word-wrap: break-word
        }

        .card-helper {
            min-height: 45px;
            overflow: hidden;

            p {
                color: #606060;
                font-size: 16px;
                font-weight: 400;
                line-height: 20px;
                letter-spacing: 0.59px;
                word-wrap: break-word
            }
        }
    }
}

.label-cards {
    h2 {
        font-size: 32px;
        height: 57px;
        font-weight: 700;
        line-height: 38px;
        letter-spacing: 2.36px;
        color: #464647;
        margin-bottom: 0px;
    }

    p {
        color: #C80E15;
        font-size: 18px;
        font-weight: 600;
        text-decoration: underline;
        line-height: 27px;
        letter-spacing: 0.66px;
        cursor: pointer;
        margin-bottom: 0px;
        height: 56px;
    }
}

.btns-swiper {
    display: flex;
    background: #ECECEC;
    height: fit-content;
    box-shadow: 0px 0px 3px rgba(40, 76, 122, 0.69) inset;
    border-radius: 5px;
    border: 1px #DBE0E6 solid;

    div {
        padding: 5px 14px;
        font-size: 22px;
        color: #1C355E;
        cursor: pointer;
    }

    .button-prev {
        border-right: 1px #DBE0E6 solid;

    }
}

.primary-card:hover {
    transform: translateY(-5px) scale(1.005) translateZ(0);
    box-shadow: 0 24px 36px rgba(0, 0, 0, 0.11),
        0 24px 46px rgba(255, 215, 97, 0.48);
    ;
}

.primary-card:active {
    transform: scale(1) translateZ(0);
    box-shadow: 0 15px 24px rgba(0, 0, 0, 0.11),
        0 15px 24px rgba(255, 215, 97, 0.48);
    ;
}

@media only screen and (-webkit-min-device-pixel-ratio: 2),
only screen and (min--moz-device-pixel-ratio: 2),
only screen and (-o-min-device-pixel-ratio: 2/1),
only screen and (min-device-pixel-ratio: 2),
only screen and (min-resolution: 192dpi),
only screen and (min-resolution: 2dppx) {

    /* Styles for high-density (Retina) screens */
    .primary-card {
        width: 100%;
        opacity: 0.90;
        background: white;
        box-shadow: 0px 5px 15px 1px rgba(0, 0, 0, 0.10);
        border-radius: 5px;
        backdrop-filter: blur(27.18px);
        transition: all 0.3s ease-out;

        .card-img {
            width: 100%;
            height: 291px !important;
            border-radius: 5px;
            object-fit: cover;
        }

        .card-content {
            padding: 25px 22px;

            .card-header {
                color: #30302F;
                font-size: 24px;
                font-weight: 600;
                line-height: 27px;
                letter-spacing: 0.88px;
                word-wrap: break-word
            }

            .card-helper {
                min-height: 60px;
                overflow: hidden;

                p {
                    color: #606060;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 20px;
                    letter-spacing: 0.59px;
                    word-wrap: break-word
                }
            }
        }
    }

    .label-cards {
        h2 {
            font-size: 32px;
            font-weight: 700;
            line-height: 38px;
            letter-spacing: 2.36px;
            color: #464647;
        }

        p {
            color: #C80E15;
            font-size: 18px;
            font-weight: 600;
            text-decoration: underline;
            line-height: 27px;
            letter-spacing: 0.66px;
            cursor: pointer;
        }
    }

    .btns-swiper {
        display: flex;
        background: #ECECEC;
        height: fit-content;
        box-shadow: 0px 0px 3px rgba(40, 76, 122, 0.69) inset;
        border-radius: 5px;
        border: 1px #DBE0E6 solid;

        div {
            padding: 5px 14px;
            font-size: 22px;
            color: #1C355E;
            cursor: pointer;
        }

        .button-prev {
            border-right: 1px #DBE0E6 solid;

        }
    }
}

.mobile-device {
    margin-top: 3rem !important;
}


@media only screen and (max-width: 767px) {

    /* Styles for small screens (e.g., mobile devices) */
    /* Adjust styles for mobile layout here */
    .primary-card {
        width: 100%;
        opacity: 0.90;
        background: white;
        box-shadow: 0px 5px 15px 1px rgba(0, 0, 0, 0.10);
        border-radius: 5px;
        backdrop-filter: blur(27.18px);
        transition: all 0.3s ease-out;

        .card-img {
            width: 100%;
            height: 291px !important;
            border-radius: 5px;
            object-fit: cover;
        }

        .card-content {
            padding: 16px !important;

            .card-header {
                color: #30302F;
                font-size: 16px !important;
                font-weight: 600;
                line-height: 17.55px !important;
                /* 109.687% */
                letter-spacing: 0.587px !important;
                word-wrap: break-word
            }

            .card-helper {
                min-height: 30px;
                overflow: hidden;

                p {
                    color: #606060;
                    font-size: 12px !important;
                    font-weight: 400;
                    font-weight: 400;
                    line-height: 17.55px !important;
                    /* 146.25% */
                    letter-spacing: 0.44px !important;
                    word-wrap: break-word
                }
            }
        }
    }

    .label-cards {
        h2 {
            font-size: 22px !important;
            font-weight: 700;
            line-height: 35px !important;
            letter-spacing: 1.621px;
            color: #464647;
        }

        p {
            color: #C80E15;
            font-size: 14px !important;
            font-style: normal;
            font-weight: 600 !important;
            line-height: 18.9px !important;
            /* 135% */
            letter-spacing: 0.513px !important;
            text-decoration: underline;

            cursor: pointer;
        }
    }

    .btns-swiper {
        display: flex;
        background: #ECECEC;
        height: fit-content;
        box-shadow: 0px 0px 3px rgba(40, 76, 122, 0.69) inset;
        border-radius: 5px;
        border: 1px #DBE0E6 solid;

        div {
            padding: 5px 14px;
            font-size: 22px;
            color: #1C355E;
            cursor: pointer;
        }

        .button-prev {
            border-right: 1px #DBE0E6 solid;

        }
    }

    .mobile-device {
        margin-top: 20px !important;
    }
}