.form-modal {
    background: #2D5E97;
    padding: 44px 33px !important;

    .form-label {
        color: white;
        font-size: 17px;
        font-weight: 400;
        line-height: 26px;
        word-wrap: break-word
    }

    .form-header {
        color: white;
        font-size: 28px;
        font-weight: 600;
        line-height: 26px;
        letter-spacing: 0.56px;
        word-wrap: break-word
    }

    .close-modal {
        position: absolute;
        top: 20px;
        right: 20px;
        cursor: pointer;
    }

    .forgot-password {
        color: white;
        font-size: 17px;
        font-weight: 400;
        text-decoration: underline;
        line-height: 26px;
        word-wrap: break-word;
        cursor: pointer;
    }


    .password-helper {
        color: #FFF;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px
    }
}

@media only screen and (max-width: 767px) {
    .form-modal {
        // width: 90% !important;
    }
}