.footer-content {
    padding: 40px 0px;
    flex-shrink: 0;

    .content {
        align-items: baseline;

        .content-card {

            h2 {
                color: white;
                font-size: 22px;
                font-weight: 700;
                text-transform: uppercase;
                letter-spacing: 0.44px;
                word-wrap: break-word;
            }

            span {
                display: block;
                color: white;
                font-size: 16px;
                font-weight: 400;
                line-height: 36px;
                letter-spacing: 0.59px;
                word-wrap: break-word;
                text-decoration: none;
                cursor: pointer;

                a {
                    color: white;
                    text-decoration: none;
                }

            }

            span:hover {
                transform: translateY(-1px) scale(1.005) translateZ(0);
            }
        }
    }

    .subscription {
        padding: 38px 52px;
        border-radius: 5px 5px 3px 3px;
        background: #2D5E97;

        .email-input {
            align-items: center;

            label {
                color: white;
                font-size: 18px;
                font-weight: 600;
                letter-spacing: 0.40px;
                word-wrap: break-word;
                white-space: nowrap !important;
                text-align: right;
            }

            input {
                border-radius: 20px;
                border: 0px transparent;
                color: #606060;
                font-size: 12px;
                font-weight: 400;
                line-height: 20px;
                letter-spacing: 0.59px;
                word-wrap: break-word;
                padding: 10px 10px;
                width: 100%;
            }

            .s-button {
                text-align: left;
            }

            input:focus {
                outline: none;
            }
        }

        .social-media {
            text-align: center;

            img {
                margin-right: 30px;
                cursor: pointer;
            }
        }
    }
}


@media only screen and (max-width: 767px) {
    .footer-content {
        padding: 0px !important;

        .content {
            text-align: center !important;
        }
    }

    .subscription {
        padding: 20px !important;
        background: #2D5E97;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: 3px;

        .email-input {
            align-items: center;

            label {
                color: white;
                font-size: 20px;
                font-weight: 600;
                letter-spacing: 0.40px;
                word-wrap: break-word;
                text-align: center !important;
                padding-bottom: 15px;
            }

            input {
                border-radius: 20px;
                border: 0px transparent;
                color: #606060;
                font-size: 16px;
                font-weight: 400;
                line-height: 20px;
                letter-spacing: 0.59px;
                word-wrap: break-word;
                padding: 10px 10px !important;
                margin-bottom: 15px;
            }

            .s-button {
                text-align: center !important;
                padding-bottom: 15px;
            }

            input:focus {
                outline: none;
            }
        }

    }

    .social-media {
        padding: 20px !important;
        // border-top: 2px solid #4575AC !important;
        text-align: center !important;

        img {
            margin-right: 35px;
            cursor: pointer;
        }
    }
}