@import 'variables';


.primary-bg {
  background: linear-gradient(0deg, rgba(45, 94, 151, 0.38) 16.68%, rgba(28, 53, 94, 0.90) 100%), #1C355E !important;
}

.primary-color {
  color: $primary-color !important;
}

.secondary-bg {
  background: $secondary-color !important;
}

.secondary-color {
  color: $secondary-color !important;
}

.terinary-bg {
  background: $terinary-color !important;
}

.terinary-color {
  color: $terinary-color !important;
}

.bg-white {
  background: white !important;
}

.error-code {
  color: red;
  margin: 0px;
  padding: 0px;
  padding-top: 5px;
  font-size: 14px !important;
}