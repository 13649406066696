.my-profile-form-container {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    padding: 60px 0 30px 0;

    .my-profile-header {
        justify-content: space-between;

        .my-profile-heading {
            font-size: 32px;
            font-weight: 700;
            line-height: 38px;
            letter-spacing: 2.3578946590423584px;
            text-align: left;
            color: #464647;
        }

    }
    .my-profile-small {
        color: #2E3823;
        font-family: Open Sans;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 0.4px;
    }

    .my-profile-form {
        padding: 40px 0 40px 0;

        .my-profile-form-contents {
            display: flex;
            justify-content: space-between;

            .form-part-one,
            .form-part-two {
                width: 45%;

                .update-email-password {
                    gap: 1.5rem;
                }

                .split-input {
                    width: 100%;
                    display: flex;
                    gap: 1rem;

                    section:nth-child(1) {
                        width: 60%;
                    }

                    section:nth-child(2) {
                        width: 40%;
                    }
                }
            }


        }
    }
}

.profile-form-button-container {
    padding-top: 80px;
    display: flex;
    gap: 2rem;
}

.radio-group {

    .radio-header {
        font-size: 13px;
        font-weight: 700;
        line-height: 18px;
        letter-spacing: 0.02em;
        text-align: left;
    }
}

.checkbox-container {
    display: flex;

    .checkbox-left,
    .checkbox-right {
        width: 50%;
    }
}



@media (max-width: 793px) {
    .my-profile-form-container {
        width: 90%;

        .my-profile-header {
            justify-content: center;

            .my-profile-heading {
                width: 90%;
                text-align: left;
            }
        }

        .my-profile-form {


            .my-profile-form-contents {
                flex-direction: column;

                .form-part-one,
                .form-part-two {
                    width: 100%;
                }
            }
        }
    }

    .checkbox-container {
        flex-direction: column;
    }

    .profile-form-button-container {
        padding-top: 20px;
        gap: 0;
        justify-content: space-around;
    }
}