.conatct-note {
    color: #2E3823;
    text-decoration: none;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5866667032px;
    text-align: left;

}

.conatct-note-header {
    // font-size: 18px;
    // font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.5866667032px;
    text-align: left;
    color: #2E3823;
    text-decoration: none;
}